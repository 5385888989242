<template>
  <div class="store-bigbox">
    <div class="store-centerBox">
      <tinymceEditor
        :Deposit_certificate="'请输入存证内容，存证内容不会被收录进入数据库...'"
        @handleChangeEditor="handleChangeEditor"
      />
    </div>
    <div class="store-centerBox" v-show="false">
      <div class="store-centerBox-title">
        <div class="centerBox-title-left" @click="openRename">
          {{ centerBoxTitle }}.docx
        </div>
        <div class="activeNum">字符数：{{ font.length }}</div>
      </div>
      <quill-editor
        ref="text"
        v-model="content"
        class="myQuillEditor"
        :options="editorOption"
        @change="EditorChange"
      />
    </div>
    <div class="store-rightBox">
      <div class="store-rightBox-kind" @click="previewSample">
        <span :data-fileSampleUrl="require('assets/images/sample-trusted.png')">
          <img
            :src="require('assets/images/view-kind.png')"
            alt=""
          />可信认证样本
        </span>
        <span
          :data-fileSampleUrl="require('assets/images/sample-official.png')"
        >
          <img
            :src="require('assets/images/view-kind.png')"
            alt=""
          />官方认证样本
        </span>
      </div>
      <div class="store-rightBox-void">
        <div class="store-rightBox-void-imgBox">
          <img :src="require('assets/images/storeBgc.png')" alt="" />
        </div>
        <div class="store-rightBox-void-btn" @click="historyStoreList">
          历史存证
        </div>
      </div>
    </div>
    <!-- 重命名 -->
    <el-dialog
      title="重命名"
      :visible.sync="rename"
      class="rename"
      :modal="false"
      top="0"
      :before-close="handleCloseRename"
    >
      <div class="content">
        <div class="content-label">文件名</div>
        <input type="text" v-model="fileNameInput" class="content-input" />
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="footer-btn sure" @click="sureRename">确定</div>
        <div class="footer-btn" @click="handleCloseRename">取消</div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import tinymceEditor from "components/editor/tinymceEditor.vue";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
export default {
  data() {
    return {
      emptyBtnSwitch: true,
      content: "", //带标签的富文本字符
      changeText: [], //记录富文本字符改变
      font: "", //不带标签的富文本字符
      editorOption: {
       // placeholder: "请输入溯源内容，溯源内容不会被收录进入数据库",
        modules: {
          toolbar: {
            container: [
              //自定义工具栏选项
              //工具栏设置
              ["revoke", "redo", "preserve"], //添加的按钮
              [{ font: [] }], //字体
              [{ size: ["small", false, "large", "huge"] }], // 字体大小
              ["bold", "italic", "underline", "strike"], //加粗，斜体，下划线，删除线

              //  [{ 'header': 1 }, { 'header': 2 }],        // 标题，键值对的形式；1、2表示字体大小
              [{ color: [] }], // 字体颜色，字体背景颜色
              [{ align: [] }], //对齐方式
              [{ script: "sub" }, { script: "super" }], // 上下标
              ["clean"], //清除字体样式
              //[{ 'indent': '-1'}, { 'indent': '+1' }],     // 缩进
              //  [{ 'direction': 'rtl' }],             // 文本方向
              // [{ header: [1, 2, 3, 4, 5, 6, false] }], //几级标题
            ],
            handlers: {
              //给自定义按钮添加的事件
              revoke: () => {
                //撤销
                this.$refs.text.quill.history.undo();
              },
              redo: () => {
                //恢复
                this.$refs.text.quill.history.redo();
              },
              preserve: () => {
                console.log("保存");
              },
            },
          },
        },
      },
      initButton: function () {
        //在使用的页面中初始化按钮样式
        let revoke = document.querySelector(".ql-revoke"); //获取元素
        let redo = document.querySelector(".ql-redo");
        let preserve = document.querySelector(".ql-preserve");
        let Front = document.createElement("i"); //创建元素
        let after = document.createElement("i");
        let preserveIcon = document.createElement("i");
        Front.className = "revoke"; //新增class属性
        after.className = "redo";
        preserveIcon.className = "preserve";
        revoke.appendChild(Front); //追加到元素中
        redo.appendChild(after);
        preserve.appendChild(preserveIcon);
      },
      // 重命名弹窗
      rename: false,
      // 重命名输入框数据
      fileNameInput: "",
      centerBoxTitle: "未命名", //富文本的标题名字
    };
  },
  mounted() {
    this.initButton();
  },
  components: {
    quillEditor,
    tinymceEditor,
  },
  watch: {
    content: {
      handler: function (cur) {
        if (cur && cur != "") {
          this.font = cur.replace(/<[^>]+>/g, "");
        } else {
          this.font = "";
        }
      },
      deep: true,
    },
  },
  methods: {
    handleChangeEditor(val) {
      // this.Editorhtml = val[0];
      this.content = val[0];
      // this.EditorSum = val[1];
      this.centerBoxTitle = val[2];
    },
    //确定重命名
    sureRename() {
      if (/ /.test(this.fileNameInput)) {
        return this.$message({
          type: "error",
          message: "不能有空格字符",
        });
      }
      this.centerBoxTitle = this.fileNameInput;
      this.rename = false;
    },
    //预览样本
    previewSample(e) {
      const target = e.target || e.srcElement;
      if (target.dataset.filesampleurl) {
        window.open(target.dataset.filesampleurl);
      }
    },
    //历史存证
    historyStoreList() {
      this.$store.commit("CHECK_USER_OPTIONS", {
        afterCheck: () => {
          this.$router.push({
            path: "/store/storeRightList",
          });
        },
      });
    },
    // 富文本监听事件
    EditorChange() {
      if (this.changeText.length >= 50) {
        //限制数组长度
        this.changeText.shift(); //清除第一条数据
        this.changeText.push(this.content);
      } else {
        this.changeText.push(this.content); //追加数据
      }
    },
    // 打开重命名
    openRename() {
      this.rename = true;
      this.fileNameInput = this.centerBoxTitle;
    },
    //  重命名弹窗关闭触发
    handleCloseRename() {
      this.rename = false;
      this.fileNameInput = "";
    },
  },
  created() {
    this.$bus.$on("contentStore", () => {
      if (!this.content) {
        return this.$message({
          type: "error",
          message: "请输入内容或上传文件",
        });
      }
      // this.showPayment = true;
      this.$bus.$emit("emitContentParams", {
        content: this.content,
        fileName: this.centerBoxTitle,
      });
    });
  },
  destroyed() {
    this.$bus.$off("contentStore");
  },
};
</script>

<style>
/* 富文本汉化 */
p {
  margin: 10px;
}
.ql-formats {
  margin-right: 0px !important;
}
.edit_container,
.quill-editor {
  height: 300px;
}
.ql-snow .ql-picker.ql-font {
  width: 83px !important;
}
.ql-snow .ql-picker.ql-size {
  width: 87px !important;
}
.revoke {
  display: inline-block;
  width: 15px;
  height: 14px;
  background-image: url("../../../assets/images/undo.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.redo {
  display: inline-block;
  width: 15px;
  height: 14px;
  background-image: url("../../../assets/images/redo.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.preserve {
  display: inline-block;
  width: 15px;
  height: 15px;
  background-image: url("../../../assets/images/preserve.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: "14px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="small"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
  content: "10px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="large"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
  content: "18px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="huge"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
  content: "32px";
}

.ql-snow .ql-picker.ql-header .ql-picker-label::before,
.ql-snow .ql-picker.ql-header .ql-picker-item::before {
  content: "文本";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  content: "标题1";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  content: "标题2";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  content: "标题3";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
  content: "标题4";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
  content: "标题5";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
  content: "标题6";
}

.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: "标准字体";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
  content: "衬线字体";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
  content: "等宽字体";
}
</style>

<style lang="scss" scoped>
.store-bigbox {
  flex: 1;
  display: flex;
  .store-centerBox {
    flex: 1;
    width: 773px;
    position: relative;
    &-title {
      display: flex;
      height: 30px;
      background: #f7f7f7;
      align-items: center;
      justify-content: space-between;
      .centerBox-title-left {
        min-width: 170px;
        max-width: 200px;
        height: 30px;
        background: #ffffff;
        text-indent: 2em;
        line-height: 30px;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
        cursor: pointer;
      }
      .activeNum {
        font-size: $font-size-medium;
        font-family: PingFang SC;
        font-weight: 500;
        color: $color-text-gray;
        margin-right: 13px;
      }
    }
    ::v-deep .myQuillEditor {
      .ql-toolbar {
        border: none;
      }
      .ql-container.ql-snow {
        border: none;
        height: 857px;
      }
    }
  }
  .store-rightBox {
    @include flex-center(column);
    justify-content: normal;
    width: 524px;
    border-left: 1px solid #eceff1;
    &-kind {
      @include flex-start;
      align-self: flex-end;
      margin-top: 17px;
      margin-right: 17px;
      > span {
        font-size: 14px;
        font-weight: 500;
        color: #4587ff;
        cursor: pointer;
        img {
          width: 14px;
          height: 14px;
          margin-right: 5px;
          vertical-align: middle;
        }
        &:first-child ~ span {
          margin-left: 29px;
        }
      }
    }
    .store-rightBox-void {
      @include flex-center(column);
      justify-content: normal;
      margin-top: 36px;
      .store-rightBox-void-imgBox {
        width: 227px;
        height: 212px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .store-rightBox-void-btn {
        margin-top: 50px;
        width: 101px;
        height: 37px;
        border: 1px solid #4587ff;
        border-radius: 3px;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #4587ff;
        line-height: 37px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
  // 重命名弹窗样式
  .rename {
    ::v-deep .el-dialog {
      width: 428px;
      height: 253px;
      background: #ffffff;
      border: 0px solid #818790;
      box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .el-dialog__header {
        padding: 0;
        height: 42px;
        background: #f4f6f7;
        display: flex;
        align-items: center;
        .el-dialog__title {
          margin-left: 14px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #393939;
        }
        .el-dialog__headerbtn {
          top: 12px;
          right: 12px;
        }
      }
      .el-dialog__body {
        padding: 50px 30px 0;
        .content {
          display: flex;
          height: 50px;
          align-items: center;
          &-label {
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #333333;
            margin-right: 12px;
          }
          &-input {
            width: 290px;
            height: 50px;
            background: #ffffff;
            border: 1px solid #cecece;
            border-radius: 5px;
            padding-left: 20px;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #000000;
          }
        }
      }
      .el-dialog__footer {
        display: flex;
        justify-content: flex-end;
        padding: 50px 30px 0 0;
        .dialog-footer {
          display: flex;
          .footer-btn {
            width: 71px;
            height: 40px;
            background: #ffffff;
            border: 1px solid #cecece;
            border-radius: 3px;
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #999999;
            text-align: center;
            line-height: 40px;
            cursor: pointer;
          }
          .sure {
            background: #4c8cff;
            color: #ffffff;
            margin-right: 12px;
          }
        }
      }
    }
  }
}
</style>
